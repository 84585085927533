"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var encoding_1 = require("./encoding");
var clientVersion = process.env.npm_package_version;
// const MASTERSERVER_IP = 'master.aceattorneyonline.com:27014';
var serverlist_domain = "servers.aceattorneyonline.com";
var protocol = window.location.protocol;
var serverlist_cache_key = "masterlist";
var servers = [];
servers[-1] = {
    name: "Localhost",
    description: "This is your computer on port 50001",
    ip: "127.0.0.1",
    players: 0,
    online: "Localhost",
    ws_port: 50001,
};
function main() {
    getServerlist().then(function (serverlist) {
        processServerlist(serverlist);
    });
    addServer(servers[-1]);
    processClientVersion(clientVersion);
    getMasterVersion().then(function (masterVersion) {
        processMasterVersion(masterVersion);
    });
}
main();
// Fetches the serverlist from the masterserver
// Returns a properly typed list of servers
function getServerlist() {
    return __awaiter(this, void 0, void 0, function () {
        var url, response, data, serverlist, data_1, data_1_1, item, newServer;
        var e_1, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = "".concat(protocol, "//").concat(serverlist_domain, "/servers");
                    return [4 /*yield*/, fetch(url)];
                case 1:
                    response = _b.sent();
                    if (!response.ok) {
                        console.error("Bad status code from masterserver. status: ".concat(response.status, ", body: ").concat(response.body));
                        document.getElementById("ms_error").style.display = "block";
                        // If we get a bad status code, try to use the cached serverlist
                        return [2 /*return*/, getCachedServerlist()];
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _b.sent();
                    serverlist = [];
                    try {
                        for (data_1 = __values(data), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                            item = data_1_1.value;
                            if (!item.name) {
                                console.warn("Server ".concat(item, " has no name, skipping"));
                                continue;
                            }
                            if (!item.ip) {
                                console.warn("Server ".concat(item.name, " has no ip, skipping"));
                                continue;
                            }
                            if (!item.description) {
                                console.warn("Server ".concat(item.name, " has no description, skipping"));
                                continue;
                            }
                            newServer = {
                                name: item.name,
                                description: item.description,
                                ip: item.ip,
                                players: item.players || 0,
                                online: "Players: ".concat(item.players),
                            };
                            if (item.ws_port) {
                                newServer.ws_port = item.ws_port;
                            }
                            if (item.wss_port) {
                                newServer.wss_port = item.wss_port;
                            }
                            // if none of ws_port or wss_port are defined, skip
                            // Note that this is not an error condition, as many servers only has port (TCP) enabled
                            // Which means they don't support webAO
                            if (!newServer.ws_port && !newServer.wss_port) {
                                continue;
                            }
                            serverlist.push(newServer);
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (data_1_1 && !data_1_1.done && (_a = data_1.return)) _a.call(data_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    // Always cache the result when we get it
                    localStorage.setItem(serverlist_cache_key, JSON.stringify(serverlist));
                    return [2 /*return*/, serverlist];
            }
        });
    });
}
function getCachedServerlist() {
    // If it's not in the cache, return an empty list
    var cached = localStorage.getItem(serverlist_cache_key) || "[]";
    return JSON.parse(cached);
}
// Constructs the client URL robustly, independent of domain and path
function constructClientURL(protocol) {
    var clientURL = new URL(window.location.href);
    // Use the given protocol
    clientURL.protocol = protocol;
    // Remove the last part of the pathname (e.g., "index.html")
    var pathname = clientURL.pathname;
    var parts = pathname.split("/");
    parts.pop();
    // Reconstruct the pathname
    clientURL.pathname = parts.join("/");
    // If clientURL.pathname does not end with a slash, add one
    if (clientURL.pathname[clientURL.pathname.length - 1] !== "/") {
        clientURL.pathname += "/";
    }
    clientURL.pathname += "client.html";
    return clientURL.href;
}
function addServer(server) {
    var ws_port = 0;
    var ws_protocol = "";
    var http_protocol = "";
    if (server.ws_port) {
        ws_port = server.ws_port;
        ws_protocol = "ws";
        http_protocol = "http";
    }
    if (server.wss_port && !window.navigator.userAgent.includes("Nintendo")) {
        ws_port = server.wss_port;
        ws_protocol = "wss";
        http_protocol = "https";
    }
    if (ws_port === 0 || ws_protocol === "" || http_protocol === "") {
        console.warn("Server ".concat(server.name, " has no websocket port, skipping"));
        return;
    }
    var clientURL = constructClientURL(http_protocol);
    var connect = "".concat(ws_protocol, "://").concat(server.ip, ":").concat(ws_port);
    var serverName = server.name;
    var fullClientWatchURL = "".concat(clientURL, "?mode=watch&connect=").concat(connect, "&serverName=").concat(serverName);
    var fullClientJoinURL = "".concat(clientURL, "?mode=join&connect=").concat(connect, "&serverName=").concat(serverName);
    servers.push(server);
    document.getElementById("masterlist").innerHTML +=
        "<details name=\"servers\">" +
            "<summary><p>".concat((0, encoding_1.safeTags)(server.name), " (").concat(server.players, ")</p>") +
            "<a class=\"button\" href=\"".concat(fullClientJoinURL, "\" target=\"_blank\">Join</a>") +
            "<a class=\"button\" href=\"".concat(fullClientWatchURL, "\" target=\"_blank\">Watch</a></summary>") +
            "<p>".concat((0, encoding_1.safeTags)(server.description), "</p>") +
            "</details>";
}
function processServerlist(serverlist) {
    for (var i = 0; i < serverlist.length; i++) {
        addServer(serverlist[i]);
    }
}
function getMasterVersion() {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(protocol, "//").concat(serverlist_domain, "/version");
                    return [4 /*yield*/, fetch(url)];
                case 1:
                    response = _a.sent();
                    if (!response.ok) {
                        console.error("Bad status code from masterserver version check. status: ".concat(response.status, ", body: ").concat(response.body));
                        return [2 /*return*/, "Unknown"];
                    }
                    return [4 /*yield*/, response.text()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
function processClientVersion(data) {
    document.getElementById("clientinfo").innerHTML = "Client version: ".concat(data);
}
function processMasterVersion(data) {
    document.getElementById("serverinfo").innerHTML =
        "Master server version: ".concat(data);
}
